<template>
  <section class="container pb-8" v-if="isReedy">
    <div class="row">
      <!-- 課程影片 -->
      <div class="col-12 col-md-8 pr-md-0 mb-5 mb-md-0">
        <h4 class="bg-accent text-center text-white py-1 py-lg-3">{{ data.courseName }}</h4>
        <div class="bg-outer border-shadow"
          :style="`--content:'${cssContent}'`">
          <div
            class="videoWrapper videoWrapper100 js-videoWrapper bg-image"
            :style="{
              backgroundImage: 'url(' + data.contentImagePath + ')',
            }"
          >
            <div class="videoIframe js-videoIframe" v-show="hasVideo">
              <video id="player" playsinline controls>
              </video>
            </div>
          </div>
        </div>
      </div>
      <!-- 課程觀看列表 -->
      <div class="col-md-4 d-block d-md-flex flex-column justify-content-between pl-md-0">
        <h5 class="gotoclass-title bg-light text-center py-lg-3">課程觀看列表</h5>
        <div class="row">
          <div class="col-6 pr-0">
            <a
              class="btn btn-sm w-100 btn-primary "
              :class="{ 'btn-light': courseType !== 1 }"
              @click.prevent="courseType = 1"
            >
              <i class="fa fa-book mr-2" aria-hidden="true"></i>課程列表
            </a>
          </div>
          <div class="col-6 pl-0">
            <a
              class="btn btn-sm w-100 btn-primary "
              :class="{ 'btn-light': courseType !== 2 }"
              @click.prevent="courseType = 2"
            >
              <i class="fa fa-heart mr-2" aria-hidden="true"></i>我的最愛
            </a>
          </div>
        </div>
        <ul class="gotolist cs-side justify-self-stretch bg-light border-shadow" data-style="2">
          <template v-if="courseData.length">
            <li
              class="side__box"
              :class="{ active: index === 0 }"
              v-for="(item, index) in courseData"
              :key="`${index}-${item.subjectId}`"
              @click="ToggleClass($event)"
            >
              <div class="gotolist__head side__title">
                <div class="gotolist__head-td">
                  <i class="fa fa-th pr-2" aria-hidden="true"></i> {{ item.groupName }}
                </div>
                <div class="gotolist__head-td">{{ item.buyCourseClassRoomList.length }} 堂</div>
                <div class="gotolist__head-td">
                  <i class="fa fa-chevron-up icon-icon_arrow" aria-hidden="true"></i>
                </div>
              </div>
              <ul class="gotolist__content side__content">
                <li
                  style="position: relative;"
                  :style="{
                    backgroundColor: item2.classCode === currentPlay.classCode ? '#e1e1e1' : '',
                  }"
                  v-for="(item2, index2) in item.buyCourseClassRoomList"
                  :key="`${index2}-${item2.classId}`"
                >
                  <i
                    class="fa fa-play-circle playing"
                    aria-hidden="true"
                    v-if="item2.classCode === currentPlay.classCode"
                  ></i>
                  <a
                    href="#"
                    class="js-videoPoster "
                    :class="{ comingsoon: item2.hasVideo === false }"
                    @click.prevent="OpenVideo(item2)"
                  >
                    {{ item2.className }}({{ item2.teacherName }})
                  </a>
                  <div class="LearningInfo">
                    <div>
                      <i
                        class="fa fa-check-circle"
                        :style="{ color: item2.isLook ? '#cccf2d' : '#c0c4cc' }"
                      ></i>
                    </div>
                    <div
                      :class="{ comingsoon: item2.hasVideo === false }"
                      :style="{ color: item2.hasVideo ? '#e6a23c' : '' }"
                    >
                      觀看紀錄:({{ item2.time | secConvertTime }})
                    </div>
                    <div>
                      <a
                        href="#"
                        :class="{
                          comingsoon: item2.hasVideo === false,
                          'text-primary': item2.hasVideo === true,
                        }"
                        @click.prevent="
                          () => {
                            if (item2.hasVideo === true) handleOpenNode(item2);
                          }
                        "
                      >
                        筆記({{ item2.videoNotesCount }})
                      </a>
                    </div>
                    <div>
                      <a href="#" @click.prevent="handleFavorite(item2)">
                        <i
                          class="fa fa-heart"
                          :style="{ color: item2.isFavorite ? '#ff6363' : '#c0c4cc' }"
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </template>
          <template v-else>
            <li class="side__box py-2" style="text-align: center;">暫無資料</li>
          </template>
        </ul>
        <div class="row">
          <div class="col-4 pr-0">
            <a
              v-if="currentPlay.classLecturePath !== ''"
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              :href="currentPlay.classLecturePath"
              target="_blank"
              >補充下載</a
            >
            <a
              v-else
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click.prevent="ShowMsgForNotDowloadFile"
              >暫無補充</a
            >
          </div>
          <div class="col-4 px-0">
            <a
              v-if="currentPlay.classNotePath !== ''"
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              :href="currentPlay.classNotePath"
              target="_blank"
              >筆記下載</a
            >
            <a
              v-else
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click.prevent="ShowMsgForNotDowloadFile"
              >暫無筆記</a
            >
          </div>
          <div class="col-4 pl-0">
            <a
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click="handleOpenNode()"
              >+筆記</a
            >
          </div>
        </div>
      </div>
      <div class="col-12">
        <p>
          ※若您遇到課程播放的問題，請先到「
          <router-link to="/novices/question">
            常見問題
          </router-link>
          」參考相關訊息
        </p>
      </div>
      <!-- 課程相關資訊 -->
      <CourseInfon :courseInfo="data" :learning="true" />
      <Notes
        :showModel.sync="showNote"
        :code="currentPlay.classCode"
        :classItem="currentPlay.className"
        :videoTime="currentTime"
        :course="data.courseName"
        @changeCurrentTime="changeCurrentTime"
        @changeNoteCount="changeNoteCount"
        @pauseVoide="handlePauseVideo"
      />
    </div>
  </section>
</template>

<script>
import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import {
  GetBuyCourseContentByMember,
  GetVideoTime,
  SetVideoTime,
  GetVideoCookies,
} from '@/api/BuyCourseApi';
import { AddToFavorite, RemoveToFavorite } from '@/api/MemberRecordApi';

import CourseInfon from '@/components/CourseInfon.vue';
import Notes from '@/components/Learning/Notes.vue';
/* global $ */

export default {
  props: ['currentPage'],
  components: {
    CourseInfon,
    Notes,
  },
  data() {
    // 建立你的初始化 model data
    return {
      hasCaption: false,
      captionsSrc: '',
      hasError: false,
      showNote: 0,
      courseType: 1,
      currentPlay: '',
      isReedy: false,
      player: '',
      hasVideo: false,
      currentTime: 0,
      keepTime: 0,
      videoSource: [
        {
          src: '_720p.m3u8',
          size: 720,
        },
        {
          src: '_1080.m3u8',
          size: 1080,
        },
      ],
      cssContent: '觀看者',
      cssCaptionsBottom: '0',
      // cssCaptionsAlign: 'center',
      // captionsAlignList: ['left', 'center', 'right'],
      options: {
        clickToPlay: false,
        controls: [
          'play-large',
          'rewind',
          'play',
          'fast-forward',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'settings',
          'fullscreen',
          'pip',
        ],
        // resetOnEnd: true,
        // 'pip', 子母畫面
        captions: { active: true, language: 'auto', update: true },
        settings: ['quality', 'speed'],
        quality: { default: 720, options: [1080, 720] },
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
      },
      data: {
        courseId: 0,
        courseName: '',
        examId: 0,
        examName: '',
        categoryId: 0,
        categoryName: '',
        roomId: 0,
        roomName: '',
        courseOriPrice: 0,
        coursePrice: 0,
        courseContent: '',
        contentImagePath: '',
        courseYoutubeUrl: '',
        courseViewStartDate: this.$moment(new Date('9999/12/31')),
        courseViewEndDate: this.$moment(new Date('9999/12/31')),
        classRoomList: [
          {
            subjectId: 0,
            subjectName: '',
            buyCourseClassRoomList: [
              {
                classId: 0,
                className: '',
                teacherId: 0,
                teacherName: '',
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.GetBuyCourseContent();
  },
  mounted() {
    // this.SetCaptionsPosition();
    this.cssContent = window.btoa(unescape(this.$UserId));

    const captionsText = `WEBVTT

00:00:00.000 --> 23:00:00.000
${this.$UserAccount}`;

    const captionsBase64 = window.btoa(unescape(encodeURIComponent(captionsText)));
    this.captionsSrc = `data:@file/octet-stream;base64,${captionsBase64}`;
  },
  beforeRouteLeave(to, form, next) {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }

    if (this.player !== '') {
      this.player.destroy();
    }
    next();
  },
  computed: {
    courseData() {
      const courseData = this._.reduce(
        this.data.newClassRoomList,
        (result, o) => {
          // 課程分類透過深拷貝，避免因為filter過濾後會影響到原始資料
          // 課程列表內容則不使用深拷貝，這樣才能保持資料同步(當在不同列表的時候，增加/移除我的最愛才可以同步處理)
          const roomLis = { ...o };
          roomLis.buyCourseClassRoomList = this._.filter(o.buyCourseClassRoomList, (i) => {
            if (this.courseType === 1) return true;
            if (i.isFavorite) return true;
            return false;
          });
          if (roomLis.buyCourseClassRoomList.length > 0) result.push(roomLis);
          return result;
        },
        [],
      );

      return courseData;
    },
  },
  methods: {
    ToggleClass(event) {
      if (
        event.target.className === 'side__box'
        || event.target.className.indexOf('gotolist__head') >= 0
        || event.target.className.indexOf('fa-chevron-up') >= 0
      ) event.currentTarget.classList.toggle('active');
    },
    GetBuyCourseContent() {
      const { id } = this.$route.params;
      GetBuyCourseContentByMember({ courseId: id }).then((response) => {
        if (response.data.statusCode === 0) {
          try {
            this.data = response.data.data;
            this.$emit('update:currentPage', [
              {
                name: '',
                title: this.data.courseName,
                path: '',
              },
            ]);
            // 取出精選課程
            const classRoomList = this._.cloneDeep(this.data.classRoomList);
            // 取出自選課程
            const optionalClassRoomList = this._.flatMap(
              this._.cloneDeep(this.data.optionalClassRoomList),
              (item) => item.optionalClassRoomList,
            );

            // 已自選課程為主，保留兩邊重複的項目
            const intersection = this._.intersectionWith(
              optionalClassRoomList,
              classRoomList, (a, b) => a.subjectId === b.subjectId && a.teacherId === b.teacherId,
            );

            // 已精選課程為主，去除掉重複項目
            const unionData = this._.unionWith(classRoomList,
              optionalClassRoomList,
              (a, b) => a.subjectId === b.subjectId && a.teacherId === b.teacherId);

            // 將自選課程與精選課程重複的項目，加回去
            this._.forEach(intersection, (item) => {
              const data = this._.find(unionData,
                { subjectId: item.subjectId, teacherId: item.teacherId });
              data.buyCourseClassRoomList = data.buyCourseClassRoomList
                .concat(item.buyCourseClassRoomList);
            });

            // 再重新整理，將重複的classId剔除掉
            this._.forEach(unionData, (item) => {
              // eslint-disable-next-line no-param-reassign
              item.buyCourseClassRoomList = this._.uniqBy(item.buyCourseClassRoomList, 'classId');
            });

            this.$set(this.data, 'newClassRoomList', unionData);
            this.isReedy = true;
          } catch (error) {
            this.$router.replace('/error').catch();
          }
        } else this.$router.replace('/error').catch();
      });
    },
    GetVideoTime(name, showLoading = false) {
      this.keepTime = 0;
      this.currentTime = 0;
      return GetVideoTime({ name }, showLoading).then((response) => {
        if (response.data.statusCode === 0) {
          this.currentTime = Math.floor(response.data.data);
          // console.log('GetVideoTime =>', this.currentTime);
          this.keepTime = this.currentTime;
        }
      });
    },
    GetVideoCookies(name, showLoading = false) {
      const { id } = this.$route.params;
      return GetVideoCookies({ courseId: id, name }, showLoading).then((response) => {
        if (response.data.statusCode === 0) {
          const { data } = response.data;
          document.cookie = `CloudFront-Policy=${data.policy};path=/; max-age=86400;`;
          document.cookie = `CloudFront-Key-Pair-Id=${data.keyPairId};path=/; max-age=86400;`;
          document.cookie = `CloudFront-Signature=${data.signature};path=/; max-age=86400;`;
        }
      });
    },
    SetVideoTime(name, time, action = '') {
      if (time === 0 && action === '') {
        return;
      }

      SetVideoTime({
        name,
        time,
        action,
      });
      this.currentPlay.time = Math.floor(time);
    },
    async OpenVideo(item2) {
      const { hasVideo, classCode } = item2;
      if (this.player !== '') this.player.destroy();
      if (this.showNote !== 0) this.showNote = 0;
      this.hasVideo = false;
      if (hasVideo) {
        this.currentPlay = item2;
        await this.GetVideoCookies(classCode, true);
        await this.GetVideoTime(classCode, true);
        this.handlLoadVideo();
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '影片即將上架',
        });
      }
      this.hasVideo = hasVideo;
    },
    updateQuality(newQuality) {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    },
    canplay() {
      this.player.currentTime = this.currentTime;
      // console.log('canplay =>', '播放器時間  => ', this.player.currentTime,
      // '記錄點的時間  => ', this.currentTime, 'ended =>', this.player.ended);
      if (this.player.ended === true) {
        this.ended();
      }
      if (this.player.currentTime === this.currentTime) {
        // console.log('stop canplay');
        this.player.off('canplay', this.canplay);
        this.player.off('timeupdate', this.timeupdate);
        this.player.on('timeupdate', this.timeupdate);
      }

      const video = document.querySelector('video');
      if (document.pictureInPictureElement) {
        video.requestPictureInPicture();
      }
    },
    ended() {
      this.keepTime = 0;
      this.currentTime = 0;
      this.SetVideoTime(this.currentPlay.classCode, 0, 'EndVideo');
      this.currentPlay.isLook = true;
      this.player.destroy();
      const video = document.querySelector('video');
      const defaultOptions = this.options;
      if (Hls.isSupported()) {
        const myStorage = window.localStorage;
        // eslint-disable-next-line operator-linebreak
        const quality = JSON.parse(
          myStorage.getItem('plyr') !== null ? myStorage.getItem('plyr') : '{"quality":720}',
        );
        const Level = parseInt(quality.quality, 10) === 720 ? 0 : 1;

        const hls = new Hls({ startLevel: Level });

        hls.loadSource(`/m3u8/${this.currentPlay.classCode}/${this.currentPlay.classCode}.m3u8`);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const availableQualities = hls.levels.map((l) => l.height);
          defaultOptions.quality = {
            default: 720,
            options: availableQualities,
            forced: true,
            onChange: (e) => this.updateQuality(e),
          };

          const track = document.createElement('track');
          track.id = 'trackZh';
          track.kind = 'captions';
          track.label = '觀看者';
          track.srclang = 'zh';
          track.default = true;
          track.src = this.captionsSrc;
          if (this.hasCaption === true) {
            video.removeChild(document.getElementById('trackZh'));
          }
          video.appendChild(track);
          this.hasCaption = true;

          this.player = new Plyr(video, defaultOptions);
          this.player.currentTime = this.currentTime;
          hls.attachMedia(video);

          // 監聽錯誤
          this.player.off('error', this.handlError);
          this.player.on('error', this.handlError);

          // 監聽按下播放
          this.player.off('play', this.handlPlay);
          this.player.on('play', this.handlPlay);

          // 執行暫停就記錄
          this.player.off('pause', this.handleSaveVideoTime);
          this.player.on('pause', this.handleSaveVideoTime);

          this.player.off('canplay', this.canplay);
          this.player.on('canplay', this.canplay);
          // 播放完紀錄點歸0
          this.player.off('ended', this.ended);
          this.player.on('ended', this.ended);

          this.player.setXHRWithCredentials(true);
        });

        window.hls = hls;
      } else {
        this.player = new Plyr(video, defaultOptions);
        // 影片來源
        this.player.source = {
          type: 'video',
          sources: [
            {
              src: this.videoSource[0].src,
              size: 720,
            },
            {
              src: this.videoSource[1].src,
              size: 1080,
            },
          ],
          tracks: [
            {
              kind: 'captions',
              label: '觀看者',
              srclang: 'zh',
              src: this.captionsSrc,
              default: true,
            },
          ],
        };
        // 監聽錯誤
        this.player.off('error', this.handlError);
        this.player.on('error', this.handlError);

        // 監聽按下播放
        this.player.off('play', this.handlPlay);
        this.player.on('play', this.handlPlay);

        // 執行暫停就記錄
        this.player.off('pause', this.handleSaveVideoTime);
        this.player.on('pause', this.handleSaveVideoTime);

        this.player.off('canplay', this.canplay);
        this.player.on('canplay', this.canplay);
        // 播放完紀錄點歸0
        this.player.off('ended', this.ended);
        this.player.on('ended', this.ended);

        this.player.off('qualitychange');
        this.player.on('qualitychange', () => {
          this.player.off('timeupdate', this.timeupdate);
          this.player.off('canplay', this.canplay);
          this.player.on('canplay', this.canplay);
        });
      }

      $('video')
        .unbind()
        .on('click', () => {
          if (this.player.playing === true) this.player.pause();
          else this.player.play();
        });
    },
    timeupdate() {
      if (this.player.currentTime - this.keepTime > 60) {
        this.SetVideoTime(this.currentPlay.classCode, Math.floor(this.player.currentTime));
        this.keepTime = this.player.currentTime;
        // this.SetCaptionsPosition();
      } else if (this.player.currentTime < this.keepTime) {
        this.SetVideoTime(this.currentPlay.classCode, Math.floor(this.player.currentTime));
        this.keepTime = this.player.currentTime;
        // this.SetCaptionsPosition();
      }
      this.currentTime = this.player.currentTime;
      // this.currentPlay.time = Math.floor(this.player.currentTime);
      // console.log('timeupdate 2', this.currentTime, this.player.currentTime);
    },
    // SetCaptionsPosition() {
    //   let bottom = `${this._.random(0, 8)}0%`;
    //   while (bottom === this.cssCaptionsBottom) {
    //     bottom = `${this._.random(0, 8)}0%`;
    //   }
    //   this.cssCaptionsBottom = bottom;
    //   this.cssCaptionsAlign = this.captionsAlignList[this._.random(0, 2)];
    // },
    ShowMsgForNotDowloadFile() {
      this.$message({
        showClose: true,
        type: 'warning',
        message: `${this.currentPlay.className} 暫時沒有提供`,
      });
    },
    changeCurrentTime(time) {
      this.player.currentTime = parseInt(time, 10);
    },
    changeNoteCount(count) {
      this.currentPlay.videoNotesCount = count;
    },
    // 開啟筆記
    async handleOpenNode(item2 = null) {
      if (
        item2 != null
        && (this.currentPlay.classCode === '' || this.currentPlay.classCode !== item2.classCode)
      ) {
        await this.OpenVideo(item2);
        this.showNote = 1;
      } else {
        this.showNote = 1;
      }
      if (item2 === null) {
        this.showNote = 2;
        this.handlePauseVideo();
      }
    },
    handlePauseVideo() {
      if (this.player !== '') this.player.pause();
    },
    // 暫停就記錄
    handleSaveVideoTime() {
      if (this.player !== '') {
        this.SetVideoTime(this.currentPlay.classCode, Math.floor(this.player.currentTime));
        this.keepTime = this.player.currentTime;
        this.currentTime = this.player.currentTime;
      }
    },
    // 我的最愛
    handleFavorite(classInof) {
      if (classInof.hasVideo) {
        const { isFavorite, classCode, className } = classInof;
        const data = classInof;
        let func = AddToFavorite;
        if (isFavorite) {
          func = RemoveToFavorite;
        }
        func({ name: classCode }).then((res) => {
          if (res.data.statusCode === 0) {
            this.$message({
              showClose: true,
              type: 'success',
              message: `成功將${className}${isFavorite ? '移除' : '加入'}我的最愛`,
            });

            data.isFavorite = !isFavorite;
          }
        });
      }
    },

    handlError() {
      // console.log('handlError');
      this.player.off('timeupdate', this.timeupdate);
      this.hasError = true;
    },
    async handlPlay() {
      if (!window.hls) {
        // console.log('!window.hls => hasError:', this.hasError);
        await this.GetVideoCookies(this.currentPlay.classCode);
        if (this.hasError) {
          this.hasError = false;
          await this.GetVideoTime(this.currentPlay.classCode);
          this.player.off('canplay', this.canplay);
          this.player.on('canplay', this.canplay);
          const video = document.querySelector('video');
          video.load();
        }
      } else {
        // console.log('window.hls => hasError:', this.hasError);
        // eslint-disable-next-line no-lonely-if
        if (this.hasError === true) {
          this.hasError = false;
          this.OpenVideo(this.currentPlay);
        } else {
          await this.GetVideoCookies(this.currentPlay.classCode);
          window.hls.startLoad();
        }
      }
    },

    handlLoadVideo() {
      if (this.showNote !== 0) this.showNote = 0;
      // https://rp-bucket-streaming.s3-ap-northeast-1.amazonaws.com/
      const rootUrl = `/m3u8/${this.currentPlay.classCode}`;
      this.videoSource[0].src = `${rootUrl}/${this.currentPlay.classCode}_720.m3u8`;
      this.videoSource[1].src = `${rootUrl}/${this.currentPlay.classCode}_1080.m3u8`;
      const video = document.querySelector('video');

      if (this.player !== '') this.player.destroy();
      if (window.hls) window.hls.destroy();

      const defaultOptions = this.options;
      if (Hls.isSupported()) {
        const myStorage = window.localStorage;
        // eslint-disable-next-line operator-linebreak
        const quality = JSON.parse(
          myStorage.getItem('plyr') !== null ? myStorage.getItem('plyr') : '{"quality":720}',
        );
        const Level = parseInt(quality.quality, 10) === 720 ? 0 : 1;

        const hls = new Hls({
          startLevel: Level,
          autoStartLoad: false,
          startPosition: this.currentTime,
        });

        hls.loadSource(`${rootUrl}/${this.currentPlay.classCode}.m3u8`);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          hls.startLoad(this.currentTime);
          const availableQualities = hls.levels.map((l) => l.height);
          defaultOptions.quality = {
            default: 720,
            options: availableQualities,
            forced: true,
            onChange: (e) => this.updateQuality(e),
          };

          const track = document.createElement('track');
          track.id = 'trackZh';
          track.kind = 'captions';
          track.label = '觀看者';
          track.srclang = 'zh';
          track.default = true;
          track.src = this.captionsSrc;
          if (this.hasCaption === true) {
            video.removeChild(document.getElementById('trackZh'));
          }
          video.appendChild(track);
          this.hasCaption = true;

          this.player = new Plyr(video, defaultOptions);
          this.player.currentTime = this.currentTime;

          hls.attachMedia(video);

          // 監聽錯誤
          this.player.off('error', this.handlError);
          this.player.on('error', this.handlError);

          // 監聽按下播放
          this.player.off('play', this.handlPlay);
          this.player.on('play', this.handlPlay);

          // 執行暫停就記錄
          this.player.off('pause', this.handleSaveVideoTime);
          this.player.on('pause', this.handleSaveVideoTime);

          this.player.off('canplay', this.canplay);
          this.player.on('canplay', this.canplay);
          // 播放完紀錄點歸0
          this.player.off('ended', this.ended);
          this.player.on('ended', this.ended);

          this.player.setXHRWithCredentials(true);
        });

        window.hls = hls;
      } else {
        this.player = new Plyr(video, defaultOptions);
        // 影片來源
        this.player.source = {
          type: 'video',
          sources: [
            {
              src: this.videoSource[0].src,
              size: 720,
            },
            {
              src: this.videoSource[1].src,
              size: 1080,
            },
          ],
          tracks: [
            {
              kind: 'captions',
              label: '觀看者',
              srclang: 'zh',
              src: this.captionsSrc,
              default: true,
            },
          ],
        };

        // 監聽錯誤
        this.player.off('error', this.handlError);
        this.player.on('error', this.handlError);

        // 監聽按下播放
        this.player.off('play', this.handlPlay);
        this.player.on('play', this.handlPlay);

        // 執行暫停就記錄
        this.player.off('pause', this.handleSaveVideoTime);
        this.player.on('pause', this.handleSaveVideoTime);

        this.player.off('canplay', this.canplay);
        this.player.on('canplay', this.canplay);
        // 播放完紀錄點歸0
        this.player.off('ended', this.ended);
        this.player.on('ended', this.ended);

        this.player.off('qualitychange');
        this.player.on('qualitychange', () => {
          this.player.off('timeupdate', this.timeupdate);
          this.player.off('canplay', this.canplay);
          this.player.on('canplay', this.canplay);
        });
      }

      $('video')
        .unbind()
        .on('click', () => {
          if (this.player.playing === true) this.player.pause();
          else this.player.play();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep html {
  --plyr-color-main: #0096a4;
}

::v-deep video {
  height: 100% !important;
  width: 100% !important;
}

::v-deep .plyr {
  height: 100% !important;
  width: 100% !important;
}

::v-deep .playing {
  position: absolute;
  top: 13px;
  left: 10px;
}

::v-deep .comingsoon {
  text-shadow: -1px -1px 1px #c0c4cc, 1px 1px 1px #fff;
  color: #fff !important;
}

::v-deep div[role='menu'] {
  max-height: 100px;
  overflow-x: auto;
}

::v-deep .LearningInfo {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

::v-deep .plyr__video-wrapper::before {
  position: absolute;
  color:#ffffff;
  // color: #161515;
  top: 50%;
  left: 3%;
  z-index: 10;
  pointer-events: none;
  content: var(--content);
  height: 100%;
  width: 93%;
  // background: right 35%/10% no-repeat url(https://www.i-readerplace.com/img/footer_logo.bb3c350c.png);
  opacity: 0.1;
  font-size: 90%;
}
::v-deep .plyr__caption {
  opacity: 0.2;
  background: none;
}
::v-deep .plyr__captions {
  // bottom: var(--bottom);
  // text-align: var(--text-align);
  top: 0;
  text-align: left;
  pointer-events: none;
  transform: none !important;
}
</style>
